import React from "react";
import styles from "./index.module.scss";

interface Props {
  list: Array<Note>;
  onDelete: (id: string) => void;
}

const formatDate = (dateStr?: string) => {
  if (!dateStr) return "";

  const t = new Date(dateStr);

  return `${t.toLocaleDateString()} ${t.toLocaleTimeString()}`;
};

const List = ({ list, onDelete }: Props) => {
  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>编号</th>
            <th>姓名</th>
            <th>手机</th>
            {/* <th>公司</th> */}
            <th>留言</th>
            <th>创建时间</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((note, index) => (
            <tr key={index} style={{ margin: "1.5em" }}>
              <td>{note._id}</td>
              <td>{note.name}</td>
              <td>{note.mobile}</td>
              {/* <td>{note.company}</td> */}
              <td>{note.note}</td>
              <td>{formatDate(note.createdAt)}</td>
              <td>
                <button
                  className={styles.button}
                  onClick={() => onDelete(note._id)}
                >
                  delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
