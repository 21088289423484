import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DefaultLayout from "components/Layout/DefaultLayout";
import Login from "components/Login";
import Notes from "components/Notes";
import Articles from "components/Articles";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<DefaultLayout />}>
          <Route index element={<Navigate to="notes" />} />
          <Route path="notes" element={<Notes />} />
          <Route path="articles" element={<Articles />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
