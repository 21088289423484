import { useCallback } from "react";
import {
  RecoilState,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import { API, IdType } from "./createRestApiClient";

interface Options<T, I extends IdType = string> {
  getId: (item: T) => I;
  currentIdState: (name: string) => RecoilState<I>;
  listState: (name: string) => RecoilState<Array<T>>;
  api: API<T, I>;
  useToken: () => string;
}

export const createStateHooks = <T, I extends IdType = string>({
  getId,
  currentIdState,
  listState,
  api,
  useToken,
}: Options<T, I>) => {
  const useCurrentIdValue = (name: string) => {
    return useRecoilValue(currentIdState(name));
  };

  const useCurrentIdState = (name: string) => {
    return useRecoilState(currentIdState(name));
  };

  const useListValue = (name: string) => {
    return useRecoilValue(listState(name));
  };

  const useListState = (name: string) => {
    return useRecoilState(listState(name));
  };

  const useItemValue = (name: string, itemId: string) => {
    const list = useRecoilValue(listState(name));

    return list.find((item) => getId(item) === itemId);
  };

  const useCurrentItem = (name: string) => {
    const list = useRecoilValue(listState(name));
    const currentId = useRecoilValue(currentIdState(name));
    return currentId
      ? list.find((item) => getId(item) === currentId)
      : undefined;
  };

  const useReducer = (name: string) => {
    const setList = useSetRecoilState(listState(name));
    const [currentId, setCurrentId] = useRecoilState(currentIdState(name));

    const token = useToken();

    const getAll = useCallback(async () => {
      const { data } = await api.getAll({
        token,
      });
      if (data) setList(data);
    }, [token, setList]);

    const del = async (id: I) => {
      await api.del({ id, token });
      if (currentId === id)
        setCurrentId(typeof id === "number" ? (0 as I) : ("" as I));

      await getAll();
    };

    const update = async (id: I, entity: T) => {
      await api.update({ id, entity, token });
      await getAll();
    };

    const create = async (entity: T) => {
      const { data } = await api.create({ entity, token });
      await getAll();

      if (data) setCurrentId(getId(data));
    };

    return { getAll, del, update, create };
  };

  return {
    useCurrentIdValue,
    useCurrentIdState,
    useListValue,
    useListState,
    useItemValue,
    useCurrentItem,
    useReducer,
  };
};
