import { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import hooks from "states/article/hooks";
import { useToken } from "states/auth/hooks";
import QuillEditor from "components/QuillEditor";
import FormRow from "components/MainDetails/FormRow";
import styles from "./index.module.scss";

type FormValues = Article;

const name = "article";

const { useCurrentIdValue, useCurrentItem, useReducer } = hooks;

const Details = () => {
  const currentId = useCurrentIdValue(name);
  const currentItem = useCurrentItem(name);
  const { create, update } = useReducer(name);
  const token = useToken();

  const [contentState, setContentState] = useState("");

  const submit = async (values: FormValues) => {
    const article = {
      ...values,
      content: contentState,
    };

    if (currentId) {
      await update(currentId, article);
    } else {
      await create(article);
    }
  };

  useEffect(() => {
    setContentState(currentItem?.content || "");
  }, [currentId, currentItem]);

  return (
    <div className={styles.details}>
      <div className={styles.title}>{currentId ? "编辑" : "创建"}</div>

      <Form<FormValues>
        key={currentId}
        initialValues={currentItem ?? {}}
        onSubmit={submit}
      >
        {({ handleSubmit, form, values, submitting }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {currentId && <FormRow label="编号" control={currentId} />}

            <Field name="name" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="标题"
                  control={<input {...input} type="text" placeholder="标题" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <FormRow
              label="内容"
              control={
                <div>
                  <QuillEditor
                    value={contentState}
                    onChange={setContentState}
                  />
                </div>
              }
            />

            <div className={styles.buttons}>
              <button color="secondary" onClick={() => form.reset()}>
                重置
              </button>
              <button color="primary" type="submit" disabled={submitting}>
                {submitting ? "提交中..." : "提交"}
              </button>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default Details;
