import { atomFamily } from "recoil";

export const listState = atomFamily<Array<Article>, string>({
  key: "article/list",
  default: (_name: string) => [],
});

export const currentIdState = atomFamily<string, string>({
  key: "article/currentid",
  default: (_name: string) => "",
});
