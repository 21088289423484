import React, { Suspense, useEffect } from "react";
import hooks from "states/note/hooks";
import List from "./List";

const { useListValue, useReducer } = hooks;

const name = "notes";
const Index = () => {
  const list = useListValue(name);
  const { getAll, del } = useReducer(name);

  const onDelete = async (id: string) => {
    await del(id);
  };

  useEffect(() => {
    if (!list.length) getAll();
  }, []);

  return (
    <Suspense fallback={<div>loading...</div>}>
      <List list={list} onDelete={onDelete} />
    </Suspense>
  );
};

export default Index;
