import { atomFamily } from "recoil";

export const listState = atomFamily<Array<Note>, string>({
  key: "note/list",
  default: (_name: string) => [],
});

export const currentIdState = atomFamily<string, string>({
  key: "note/currentid",
  default: (_name: string) => "",
});
