import { useEffect } from "react";
import hooks from "states/article/hooks";
import Main from "components/MainDetails/Main";

const { useCurrentIdState, useListValue, useReducer } = hooks;

interface Props {
  stateName: string;
}

const List = ({ stateName }: Props) => {
  const [currentId, setCurrentId] = useCurrentIdState(stateName);
  const list = useListValue(stateName);
  const { getAll, del } = useReducer(stateName);

  useEffect(() => {
    getAll();
  }, [getAll]);

  const getTitle = (article?: Article) => (article ? article.name : "未命名");

  return (
    <Main
      title="文章"
      itemIds={list.map(({ _id }) => _id)}
      renderItem={(id) => (
        <span>{getTitle(list.find(({ _id }) => id === _id))}</span>
      )}
      onSelect={setCurrentId}
      onCreate={() => setCurrentId("")}
      onRemove={async (id) => {
        if (window.confirm("确认删除？")) {
          setCurrentId("");
          await del(id);
        }
      }}
      currentId={currentId}
    />
  );
};

export default List;
