import { createStateHooks } from "lib/createStateHooks";
import { useToken } from "states/auth/hooks";
import api from "api/article";
import { currentIdState, listState } from ".";

export default createStateHooks<Article>({
  getId: ({ _id }) => _id,
  currentIdState,
  listState,
  api,
  useToken,
});
