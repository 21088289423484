import { Navigate } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { login } from "api/auth";
import { useIsAuthenticated, useSetAuth } from "states/auth/hooks";
import LoginForm from "./LoginForm";

const Login = () => {
  const setAuth = useSetAuth();
  const isAuthenticated = useIsAuthenticated();
  const location = { state: null };

  const submit = async (values: LoginFormValues) => {
    const { userName, password } = values;

    const { data, error } = await login(userName, password);

    if (error) {
      return { [FORM_ERROR]: error };
    }

    if (data) {
      setAuth({
        token: data.accessToken,
        accountId: data.userName,
      });
    }
  };

  if (isAuthenticated === true) {
    const { from } = location.state || { from: { pathname: "/" } };
    if (from.pathname === "/login") from.pathname = "/";
    return <Navigate to={from} />;
  }

  return (
    <LoginForm
      onSubmit={submit}
      // isFetching={auth.isFetching}
      isFetching={false}
      // authError={auth.error}
    />
  );
};

export default Login;
