import React, { useMemo, useRef, useState } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import BlotFormatter from "quill-blot-formatter";
import "react-quill/dist/quill.snow.css";
import MediaPicker from "./MediaPicker";

Quill.register("modules/blotFormatter", BlotFormatter);

const QuillEditor = (props: ReactQuillProps) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // ["undo", "redo"],
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: () => setIsPickerOpen(true),
        },
      },
      blotFormatter: {},
    }),
    []
  );

  // const formats = [
  //   "header",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "strike",
  //   "blockquote",
  //   "list",
  //   "bullet",
  //   "indent",
  //   "link",
  //   "image",
  // ];

  const insertImage = async (url: string) => {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    const index = range?.index || 0;

    editor.insertEmbed(index || 0, "image", url);
  };

  return (
    <>
      <ReactQuill
        ref={(instance) => (quillRef.current = instance)}
        theme="snow"
        modules={modules}
        {...props}
      />
      {isPickerOpen && (
        <MediaPicker
          onClose={() => setIsPickerOpen(false)}
          onSelect={(items) => {
            items.forEach((item) => insertImage(item.imageUrl));
            setIsPickerOpen(false);
          }}
        />
      )}
    </>
  );
};

export default QuillEditor;
